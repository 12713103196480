import { render, staticRenderFns } from "./SchoolSubscriptions.vue?vue&type=template&id=403b3260&scoped=true"
import script from "./SchoolSubscriptions.vue?vue&type=script&lang=js"
export * from "./SchoolSubscriptions.vue?vue&type=script&lang=js"
import style0 from "./SchoolSubscriptions.vue?vue&type=style&index=0&id=403b3260&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "403b3260",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/ubuntu/apps/SyncGrades.Web/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('403b3260')) {
      api.createRecord('403b3260', component.options)
    } else {
      api.reload('403b3260', component.options)
    }
    module.hot.accept("./SchoolSubscriptions.vue?vue&type=template&id=403b3260&scoped=true", function () {
      api.rerender('403b3260', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/rootComponents/super/SchoolSubscriptions.vue"
export default component.exports