<template>
<div v-if="school" :key="`SchoolSubscriptions_${key}`">
    <div class="kt-portlet kt-portlet--fit kt-portlet--head-lg kt-portlet--head-overlay kt-portlet--height-fluid">
        <div class="kt-portlet__head kt-portlet__space-x">
            <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title kt-font-light kt-font-boldest">
                    Subscription
                </h3>
            </div>
            <div class="kt-portlet__head-toolbar">
                <button
                    class="btn btn-outline-light btn-sm btn-bold dropdown-toggle"
                    data-toggle="dropdown"
                >
                    {{ year }}
                </button>
                <div class="dropdown-menu dropdown-menu-right">
                    <a
                        v-for="(sub, idx) in subscriptions"
                        :key="`subscription_${idx}`"
                        class="dropdown-item"
                        href="#!"
                        @click="setYear(sub.schoolYear)"
                    > {{ sub.schoolYear }}</a>
                </div>
                <button
                    v-if="subscription && !subscription.deleted"
                    class="btn btn-danger btn-sm btn-bold ml-3"
                    @click.stop.prevent="save(true)"
                >
                    Delete
                </button>
                <button
                    v-if="subscription"
                    class="btn btn-success btn-sm btn-bold ml-3"
                    @click.stop.prevent="save(false)"
                >
                    {{ subscription.deleted ? 'Create' : 'Save' }}
                </button>
            </div>
        </div>
        <div class="kt-portlet__body">
            <div class="kt-widget28">
                <div class="kt-widget28__visual" style="background-color: #0f88ef !important;" />
                <div class="kt-widget28__wrapper kt-portlet__space-x">
                    <ul class="nav nav-pills nav-fill kt-portlet__space-x">
                        <li class="nav-item">
                            <a
                                href="#!"
                                class="nav-link"
                                :class="{ active: tab === 1 }"
                                @click.stop.prevent="tab = 1"
                            ><span><i class="fa flaticon-piggy-bank" /></span><span>Billing</span></a>
                        </li>
                        <li class="nav-item">
                            <a
                                href="#!"
                                class="nav-link"
                                :class="{ active: tab === 2 }"
                                @click.stop.prevent="tab = 2"
                            ><span><i class="fa flaticon-interface-6" /></span><span>Modules</span></a>
                        </li>
                        <li class="nav-item">
                            <a
                                href="#!"
                                class="nav-link"
                                :class="{ active: tab === 3 }"
                                @click.stop.prevent="tab = 3"
                            ><span><i class="fa flaticon-statistics" /></span><span>Usage</span></a>
                        </li>
                    </ul>

                    <div class="tab-content">
                        <div
                            class="tab-pane"
                            :class="{ 'active show': tab === 1 }"
                            style="height: 300px;"
                        >
                            <div v-if="subscription">
                                <div class="form-group row ml-1">
                                    <div class="col-lg-6 row">
                                        <label class="col-lg-9 col-form-label">
                                            <span class="kt-font-lg">Disabled</span><br>
                                            <span>Disables access to all SyncGrades features for school year</span>
                                        </label>
                                        <div class="col-3 mt-3">
                                            <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success">
                                                <label>
                                                    <input
                                                        v-model="subscription.disabled"
                                                        type="checkbox"
                                                        checked="checked"
                                                        name=""
                                                    >
                                                    <span />
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 row">
                                        <label class="col-lg-9 col-form-label">
                                            <span class="kt-font-lg">Delinquent</span><br>
                                            <span>Warns school users of delinquent status for school year</span>
                                        </label>
                                        <div class="col-3 mt-3">
                                            <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success">
                                                <label>
                                                    <input
                                                        v-model="subscription.delinquent"
                                                        type="checkbox"
                                                        checked="checked"
                                                        name=""
                                                    >
                                                    <span />
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-lg-6">
                                    <label>Subscription Message</label>
                                    <input
                                        v-model="subscription.schoolSubscriptionMessage"
                                        type="email"
                                        class="form-control"
                                    >
                                    <span class="form-text text-muted">School Users will see this message if marked delinquent or disabled</span>
                                </div>
                                <div class="kt-widget28__tab-items">
                                    <div class="kt-widget28__tab-item">
                                        <span class="font-italic">Invoicing Coming soon</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="tab-pane fade"
                            :class="{ 'active show': tab === 2 }"
                        >
                            <div v-if="subscription" class="row">
                                <div class="row col-xl-4 col-lg-6 px-4 py-2">
                                    <label class="col-md-9 col-form-label">
                                        <span class="kt-font-lg kt-font-danger">Super Admin Locked</span><br>
                                        <span class="kt-font-danger">Locks modules changes from school admins</span>
                                    </label>
                                    <div class="col-md-3 mt-3">
                                        <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--danger">
                                            <label>
                                                <input
                                                    v-model="subscription.adminLocked"
                                                    type="checkbox"
                                                    checked="checked"
                                                    name=""
                                                >
                                                <span />
                                            </label>
                                        </span>
                                    </div>
                                </div>
                                <div class="row col-xl-4 col-lg-6 px-4 py-2">
                                    <label class="col-md-9 col-form-label">
                                        <span class="kt-font-lg">Grading</span><br>
                                        <span>Enables GradeBook capabilities for school year</span>
                                    </label>
                                    <div class="col-md-3 mt-3">
                                        <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success">
                                            <label>
                                                <input
                                                    v-model="subscription.gradingEnabled"
                                                    type="checkbox"
                                                    checked="checked"
                                                    name=""
                                                >
                                                <span />
                                            </label>
                                        </span>
                                    </div>
                                </div>
                                <div class="row col-xl-4 col-lg-6 px-4 py-2">
                                    <label class="col-md-9 col-form-label">
                                        <span class="kt-font-lg">Anecdotals</span><br>
                                        <span>Enables Anecdotal Logs and Badges for school year</span>
                                    </label>
                                    <div class="col-md-3 mt-3">
                                        <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success">
                                            <label>
                                                <input
                                                    v-model="subscription.anecdotalsEnabled"
                                                    type="checkbox"
                                                    checked="checked"
                                                    name=""
                                                >
                                                <span />
                                            </label>
                                        </span>
                                    </div>
                                </div>
                                <div class="row col-xl-4 col-lg-6 px-4 py-2">
                                    <label class="col-md-9 col-form-label">
                                        <span class="kt-font-lg">Report Cards</span><br>
                                        <span>Enables Report Card Grade submission for school year</span>
                                    </label>
                                    <div class="col-md-3 mt-3">
                                        <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success">
                                            <label>
                                                <input
                                                    v-model="subscription.reportCardsEnabled"
                                                    type="checkbox"
                                                    checked="checked"
                                                    name=""
                                                >
                                                <span />
                                            </label>
                                        </span>
                                    </div>
                                </div>
                                <div class="row col-xl-4 col-lg-6 px-4 py-2">
                                    <label class="col-md-9 col-form-label">
                                        <span class="kt-font-lg">Report Generation</span><br>
                                        <span>Enables Progress Report/Report Card pdf generation</span>
                                    </label>
                                    <div class="col-md-3 mt-3">
                                        <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success">
                                            <label>
                                                <input
                                                    v-model="subscription.reportingEnabled"
                                                    type="checkbox"
                                                    checked="checked"
                                                    name=""
                                                >
                                                <span />
                                            </label>
                                        </span>
                                    </div>
                                </div>
                                <div class="row col-xl-4 col-lg-6 px-4 py-2">
                                    <label class="col-md-9 col-form-label">
                                        <span class="kt-font-lg">Encryption</span><br>
                                        <span>Enables Data Encryption for school year</span>
                                    </label>
                                    <div class="col-md-3 mt-3">
                                        <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success">
                                            <label>
                                                <input
                                                    v-model="subscription.encryptionEnabled"
                                                    type="checkbox"
                                                    checked="checked"
                                                    name=""
                                                >
                                                <span />
                                            </label>
                                        </span>
                                    </div>
                                </div>
                                <div class="row col-xl-4 col-lg-6 px-4 py-2">
                                    <label class="col-md-9 col-form-label">
                                        <span class="kt-font-lg">Communication</span><br>
                                        <span>Enables Communication features for school year</span>
                                    </label>
                                    <div class="col-md-3 mt-3">
                                        <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success">
                                            <label>
                                                <input
                                                    v-model="subscription.communicationEnabled"
                                                    type="checkbox"
                                                    checked="checked"
                                                    name=""
                                                >
                                                <span />
                                            </label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="subscription" class="row pt-4 px-4">
                                <div class="col-xl-6 col-lg-4">
                                    <div class="form-group">
                                        <label>Communication Plan</label>
                                        <select
                                            v-model="subscription.communicationPlan"
                                            :disabled="!subscription.communicationEnabled"
                                            class="form-control"
                                            style="max-width: 200px"
                                            @change="setCommunicationPlan"
                                        >
                                            <option :value="'trial'">
                                                Trial
                                            </option>
                                            <option :value="'essential'">
                                                Essential
                                            </option>
                                            <option :value="'team'">
                                                Team
                                            </option>
                                            <option :value="'corporate'">
                                                Corporate
                                            </option>
                                            <option :value="'enterprise'">
                                                Enterprise
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div v-if="!subscription.deleted" class="col-xl-3 col-lg-4">
                                    <div class="form-group">
                                        <label>SMS Quota</label>
                                        <input
                                            v-model="subscription.smsQuota"
                                            :disabled="!subscription.communicationEnabled"
                                            type="number"
                                            class="form-control"
                                        >
                                        <span class="form-text text-muted">
                                            {{ isNaN(subscription.smsQuota) ? 0 : `~\$${(subscription.smsQuota * inboundSmsCost).toFixed(2)}` }} per month
                                        </span>
                                    </div>
                                </div>
                                <div v-if="!subscription.deleted" class="col-xl-3 col-lg-4">
                                    <div class="form-group">
                                        <label>Email Quota</label>
                                        <input
                                            v-model="subscription.emailQuota"
                                            :disabled="!subscription.communicationEnabled"
                                            type="number"
                                            class="form-control"
                                        >
                                        <span class="form-text text-muted">
                                            {{ isNaN(subscription.emailQuota) ? 0 : `~\$${((subscription.emailQuota / 1000) * emailCost).toFixed(2)}` }} per month
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="tab-pane fade"
                            :class="{ 'active show': tab === 3 }"
                        >
                            <div v-if="subscription.schoolMonthlyUsages">
                                <div class="row">
                                    <div
                                        v-for="(type, idx) in ['SMS', 'Email']"
                                        :key="`usage_data_${type}, ${idx}`"
                                        class="kt-widget4 col-lg-6"
                                    >
                                        <div v-if="subscription.schoolMonthlyUsages.length" class="kt-widget4__chart">
                                            <SchoolSubscriptionsUsageLineChart
                                                :school-monthly-usages="subscription.schoolMonthlyUsages"
                                                :type="type"
                                                :height="130"
                                            />
                                        </div>
                                        <div class="kt-widget4__items kt-widget4__items--bottom kt-portlet__space-x kt-margin-b-20 mt-3">
                                            <div class="kt-widget4__item">
                                                <div class="kt-widget4__img kt-widget4__img--logo">
                                                    <img src="assets/media/client-logos/logo3.png" alt="">
                                                </div>
                                                <div class="kt-widget4__info">
                                                    <a href="#" class="kt-widget4__title">
                                                        {{ type }} Usage Data
                                                    </a>
                                                    <span class="kt-widget4__sub">
                                                        Running Total for school year
                                                    </span>
                                                </div>
                                                <span class="kt-widget4__ext">
                                                    <span class="kt-widget4__number float-right" :class="[getTotalUsageClass(type)]">
                                                        <span class="kt-font-lg">{{ getTotalUsage(type) }} Sent</span><br>
                                                        <span class="kt-font-bolder">${{ getTotalCost(type) }}</span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="kt-widget4__item">
                                                <div class="kt-widget4__img kt-widget4__img--logo">
                                                    <img src="assets/media/client-logos/logo1.png" alt="">
                                                </div>
                                                <div class="kt-widget4__info">
                                                    <a href="#" class="kt-widget4__title">
                                                        {{ currentMonth }} Usage
                                                    </a>
                                                    <span class="kt-widget4__sub">
                                                        Running total for current month
                                                    </span>
                                                </div>
                                                <span class="kt-widget4__ext">
                                                    <span class="kt-widget4__number float-right" :class="[getMonthUsageClass(type)]">
                                                        <span class="kt-font-lg">{{ getMonthUsage(type) }} Sent</span><br>
                                                        <span class="kt-font-bolder">${{ getMonthCost(type) }}</span>
                                                    </span>
                                                </span>
                                            </div>
                                            <div class="kt-widget4__item">
                                                <div class="kt-widget4__img kt-widget4__img--logo">
                                                    <img src="assets/media/client-logos/logo1.png" alt="">
                                                </div>
                                                <div class="kt-widget4__info">
                                                    <a href="#" class="kt-widget4__title">
                                                        {{ type }} Quota
                                                    </a>
                                                    <span class="kt-widget4__sub">
                                                        {{ type === 'SMS' ? `$${outboundSmsCost} per Outbound SMS`: `$${emailCost}0 per 1000 emails sent` }}
                                                    </span>
                                                </div>
                                                <span class="kt-widget4__ext">
                                                    <span class="kt-widget4__number float-right">
                                                        <span class="kt-font-lg">
                                                            {{ type === 'SMS' ? subscription.smsQuota || 'Unlimited' : subscription.emailQuota || 'Unlimited' }}
                                                        </span><br>
                                                        <span class="kt-font-xs">
                                                            per month
                                                        </span>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import async from 'async';
import moment from 'moment';
import SchoolSubscription from '../../../../server/models/SchoolSubscription';
import * as network from '../../network';
import Types from '../../store/Types';
import SchoolSubscriptionsUsageLineChart from './SchoolSubscriptionsUsageLineChart.vue';

export default {
    name: 'SchoolSubscriptions',
    components: {
        SchoolSubscriptionsUsageLineChart,
    },
    props: {
        externalSchoolId: {
            type: String,
            required: false,
            default: null,
        },
        schoolYear: {
            type: [String, Number],
            required: false,
            default: null,
        },
    },
    data() {
        return {
            key: 0,
            saving: false,
            debounced: null,
            subscription: null,
            year: moment().format('YYYY'),
            tab: 2,
            outboundSmsCost: 0.00883,
            inboundSmsCost: 0.0075,
            emailCost: 0.10, // per 1000 emails
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            userName: (state) => state.user.mappedUserName || state.user.userName,
            schools: (state) => state.database.schools,
        }),
        currentMonth() {
            return moment().format('MMMM');
        },
        school() {
            const { externalSchoolId } = this || this.$route.params;
            return this.schools.find((s) => s.externalSchoolId == externalSchoolId) || null;
        },
        subscriptions() {
            if (!this.school) return [];
            const { subscriptions, externalSchoolId } = this.school;

            const thisYear = subscriptions.find((s) => s.schoolYear == moment().format('YYYY'));
            if (!thisYear) {
                subscriptions.push(
                    new SchoolSubscription({
                        externalSchoolId,
                        deleted: true,
                        schoolYear: moment().format('YYYY'),
                        disabled: false,
                        delinquent: false,
                        schoolSubscriptionMessage: 'Your SyncGrades account balance is past due. Please submit a Purchase Order as soon as possible, or risk account termination.',
                        communicationEnabled: false,
                        communicationPlan: null,
                        gradingEnabled: false,
                        anecdotalsEnabled: false,
                        encryptionEnabled: false,
                        reportCardsEnabled: false,
                        reportingEnabled: false,
                        adminLocked: false,
                        emailQuota: null,
                        smsQuota: null,
                    }),
                );
            }

            const lastYear = subscriptions.find((s) => s.schoolYear == moment().add(-1, 'years').format('YYYY'));
            if (!lastYear) {
                subscriptions.push(
                    new SchoolSubscription({
                        externalSchoolId,
                        deleted: true,
                        schoolYear: moment().add(-1, 'years').format('YYYY'),
                        disabled: false,
                        delinquent: false,
                        schoolSubscriptionMessage: 'Your SyncGrades account balance is past due. Please submit a Purchase Order as soon as possible, or risk account termination.',
                        communicationEnabled: false,
                        communicationPlan: null,
                        gradingEnabled: false,
                        anecdotalsEnabled: false,
                        encryptionEnabled: false,
                        reportCardsEnabled: false,
                        reportingEnabled: false,
                        adminLocked: false,
                        emailQuota: null,
                        smsQuota: null,
                    }),
                );
            }

            const nextYear = subscriptions.find((s) => s.schoolYear == moment().add(1, 'years').format('YYYY'));
            if (!nextYear) {
                subscriptions.push(
                    new SchoolSubscription({
                        externalSchoolId,
                        deleted: true,
                        schoolYear: moment().add(1, 'years').format('YYYY'),
                        disabled: false,
                        delinquent: false,
                        schoolSubscriptionMessage: 'Your SyncGrades account balance is past due. Please submit a Purchase Order as soon as possible, or risk account termination.',
                        communicationEnabled: false,
                        communicationPlan: null,
                        gradingEnabled: false,
                        anecdotalsEnabled: false,
                        encryptionEnabled: false,
                        reportCardsEnabled: false,
                        reportingEnabled: false,
                        adminLocked: false,
                        emailQuota: null,
                        smsQuota: null,
                    }),
                );
            }
            const output = subscriptions
                .map((s) => new SchoolSubscription(s))
                .sort((a, b) => {
                    if (a.schoolYear < b.schoolYear) return 1;
                    if (a.schoolYear > b.schoolYear) return -1;
                    return 0;
                });

            return output;
        },
    },
    watch: {
        year: {
            handler() {
                this.subscription = this.subscriptions.find((s) => s.schoolYear == this.year) || null;
                this.key += 1;
            },
            immediate: true,
        },
    },
    mounted() {
        const { schoolYear } = this;
        if (schoolYear) this.setYear(schoolYear);
        else {
            if (moment().month() > 8) this.setYear(moment().format('YYYY'));
            else this.setYear(moment().add(-1, 'years').format('YYYY'));
        }
    },
    methods: {
        setYear(year) {
            this.year = year;
        },
        debounceQuotaSave() {
            if (this.debounced) clearTimeout(this.debounced);
            this.debounced = setTimeout(() => this.saveQuotas(), 500);
        },
        save(deleted) {
            const v = this;
            if (v.saving) return;
            v.saving = true;


            async.auto({
                subscription(next) {
                    const { subscription } = v;

                    const params = {
                        body: {
                            subscription: {
                                externalSchoolId: subscription.externalSchoolId,
                                schoolYear: subscription.schoolYear,
                                purchaseOrderNumber: subscription.purchaseOrderNumber,
                                cost: subscription.cost,
                                deleted,
                                delinquent: subscription.delinquent,
                                disabled: subscription.disabled,
                                schoolSubscriptionMessage: subscription.schoolSubscriptionMessage,
                                certifiedPurchaseOrder: subscription.certifiedPurchaseOrder,
                                invoice: subscription.invoice || null,
                                invoiceDetails: subscription.invoiceDetails || null,
                                communicationEnabled: subscription.communicationEnabled,
                                communicationPlan: subscription.communicationPlan || 'trial',
                                gradingEnabled: subscription.gradingEnabled,
                                anecdotalsEnabled: subscription.anecdotalsEnabled,
                                reportCardsEnabled: subscription.reportCardsEnabled,
                                reportingEnabled: subscription.reportingEnabled,
                                encryptionEnabled: subscription.encryptionEnabled,
                                adminLocked: subscription.adminLocked,
                            },
                        },
                    };

                    network.admin.upsertSubscription(params, next);
                },
                subscriptionQuotas: ['subscription', (results, next) => {
                    if (!results.subscription) return next(new Error('No subscription saved'));

                    const { subscription } = results.subscription;
                    if (!subscription.length) return next(new Error('No subscription saved'));

                    const { schoolSubscriptionId } = subscription[0];
                    const params = {
                        body: {
                            schoolSubscriptionQuota: {
                                schoolSubscriptionId,
                                emailQuota: isNaN(v.subscription.emailQuota) ? null : v.subscription.emailQuota,
                                smsQuota: isNaN(v.subscription.smsQuota) ? null : v.subscription.smsQuota,
                                deleted: false,
                            },
                        },
                    };

                    network.admin.editSubscriptionQuota(params, next);
                }],
            }, 5, (err) => {
                v.saving = false;
                if (err) return v.showError(err);
                if (v.externalSchoolId) window.location.reload();
                v.$store.dispatch(Types.actions.REFRESH_TABLE_BY_NAME, { tableName: 'schools', callback: (err2) => {
                    if (err2) return v.showError(err2);
                }});
            });
        },
        setCommunicationPlan() {
            const { subscription } = this;
            if (!subscription) return;

            const { communicationPlan } = subscription;
            if (communicationPlan === 'trial') {
                subscription.emailQuota = 250000;
                subscription.smsQuota = 1500;
            }
            if (communicationPlan === 'essential') {
                subscription.emailQuota = 500000;
                subscription.smsQuota = 14000;
            }
            if (communicationPlan === 'team') {
                subscription.emailQuota = 1000000;
                subscription.smsQuota = 19600;
            }
            if (communicationPlan === 'corporate') {
                subscription.emailQuota = 1000000;
                subscription.smsQuota = 20000;
            }
            if (communicationPlan === 'enterprise') {
                subscription.emailQuota = null;
                subscription.smsQuota = 42000;
            }
        },
        getTotalUsage(type) {
            const { subscription } = this;
            if (!subscription) return 0;

            return subscription.schoolMonthlyUsages.reduce((acc, usage) => {
                const { smsUsage, smsReplyUsage, emailUsage } = usage;
                if (type === 'SMS') return acc + parseInt(smsUsage) + parseInt(smsReplyUsage);
                if (type === 'Email') return acc + parseInt(emailUsage);
                return acc;
            }, 0);
        },
        getTotalCost(type) {
            const { subscription, outboundSmsCost, inboundSmsCost, emailCost } = this;
            if (!subscription) return 0;

            return subscription.schoolMonthlyUsages.reduce((acc, usage) => {
                const { smsUsage, smsReplyUsage, emailUsage } = usage;

                if (type === 'SMS') {
                    const smsSpent = (parseInt(smsUsage) * outboundSmsCost) + (parseInt(smsReplyUsage) * inboundSmsCost);
                    return acc + smsSpent;
                }
                if (type === 'Email') {
                    const emailSpent= (parseInt(emailUsage) / 1000) * emailCost;
                    return acc + emailSpent;
                }
                return acc;
            }, 0).toFixed(2);
        },
        getTotalUsageClass(type) {
            const { subscription } = this;
            if (!subscription) return type === 'SMS' ? 'kt-font-primary' : 'kt-font-success';

            const { emailQuota, smsQuota } = subscription;
            const totalUsage = this.getTotalUsage(type);

            if (type === 'SMS') {
                if (smsQuota && totalUsage >= (smsQuota * 12)) return 'kt-font-danger';
            }
            if (type === 'Email') {
                if (emailQuota && totalUsage >= (emailQuota * 12)) return 'kt-font-danger';
            }
            return type === 'SMS' ? 'kt-font-primary' : 'kt-font-success';
        },
        getMonthUsage(type) {
            const { subscription, currentMonth } = this;
            if (!subscription) return 0;

            const forMonth = subscription.schoolMonthlyUsages.find((u) => u.usageMonth == currentMonth) || null;
            if (!forMonth) return 0;

            if (type === 'SMS') {
                const { smsUsage, smsReplyUsage } = forMonth;
                return parseInt(smsUsage) + parseInt(smsReplyUsage);
            }
            if (type === 'Email') {
                const { emailUsage } = forMonth;
                return parseInt(emailUsage);
            }

            return 0;
        },
        getMonthCost(type) {
            const { subscription, currentMonth, outboundSmsCost, inboundSmsCost, emailCost } = this;
            if (!subscription) return 0;

            const forMonth = subscription.schoolMonthlyUsages.find((u) => u.usageMonth == currentMonth) || null;
            if (!forMonth) return 0;

            if (type === 'SMS') {
                const { smsUsage, smsReplyUsage } = forMonth;
                const smsSpent = (parseInt(smsUsage) * outboundSmsCost) + (parseInt(smsReplyUsage) * inboundSmsCost);
                return smsSpent.toFixed(2);
            }
            if (type === 'Email') {
                const { emailUsage } = forMonth;
                const emailSpent = (parseInt(emailUsage) / 1000) * emailCost;
                return emailSpent.toFixed(2);
            }

            return 0;
        },
        getMonthUsageClass(type) {
            const { subscription } = this;
            if (!subscription) return type === 'SMS' ? 'kt-font-primary' : 'kt-font-success';

            const { emailQuota, smsQuota } = subscription;
            const monthUsage = this.getMonthUsage(type);

            if (type === 'SMS') {
                if (smsQuota && monthUsage >= smsQuota) return 'kt-font-danger';
            }
            if (type === 'Email') {
                if (emailQuota && monthUsage >= emailQuota) return 'kt-font-danger';
            }
            return type === 'SMS' ? 'kt-font-primary' : 'kt-font-success';
        }
    },
};
</script>

<style lang="scss" scoped>
.badge {
    font-size: 1em;
    font-weight: 700;
    padding-left: 12px;
    padding-right: 12px;

    .close {
        margin-left: .25rem;
        color: inherit;
        font-size: 100%;
        text-shadow: 0 1px 0 rgba(#000, .5);
    }
}
</style>
