var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.school
    ? _c("div", { key: `SchoolSubscriptions_${_vm.key}` }, [
        _c(
          "div",
          {
            staticClass:
              "kt-portlet kt-portlet--fit kt-portlet--head-lg kt-portlet--head-overlay kt-portlet--height-fluid",
          },
          [
            _c("div", { staticClass: "kt-portlet__head kt-portlet__space-x" }, [
              _vm._m(0),
              _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-outline-light btn-sm btn-bold dropdown-toggle",
                    attrs: { "data-toggle": "dropdown" },
                  },
                  [_vm._v(" " + _vm._s(_vm.year) + " ")]
                ),
                _c(
                  "div",
                  { staticClass: "dropdown-menu dropdown-menu-right" },
                  _vm._l(_vm.subscriptions, function (sub, idx) {
                    return _c(
                      "a",
                      {
                        key: `subscription_${idx}`,
                        staticClass: "dropdown-item",
                        attrs: { href: "#!" },
                        on: {
                          click: function ($event) {
                            return _vm.setYear(sub.schoolYear)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(sub.schoolYear))]
                    )
                  }),
                  0
                ),
                _vm.subscription && !_vm.subscription.deleted
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-danger btn-sm btn-bold ml-3",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.save(true)
                          },
                        },
                      },
                      [_vm._v(" Delete ")]
                    )
                  : _vm._e(),
                _vm.subscription
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-success btn-sm btn-bold ml-3",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.save(false)
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.subscription.deleted ? "Create" : "Save"
                            ) +
                            " "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "kt-portlet__body" }, [
              _c("div", { staticClass: "kt-widget28" }, [
                _c("div", {
                  staticClass: "kt-widget28__visual",
                  staticStyle: { "background-color": "#0f88ef !important" },
                }),
                _c(
                  "div",
                  { staticClass: "kt-widget28__wrapper kt-portlet__space-x" },
                  [
                    _c(
                      "ul",
                      {
                        staticClass:
                          "nav nav-pills nav-fill kt-portlet__space-x",
                      },
                      [
                        _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.tab === 1 },
                              attrs: { href: "#!" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  _vm.tab = 1
                                },
                              },
                            },
                            [_vm._m(1), _c("span", [_vm._v("Billing")])]
                          ),
                        ]),
                        _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.tab === 2 },
                              attrs: { href: "#!" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  _vm.tab = 2
                                },
                              },
                            },
                            [_vm._m(2), _c("span", [_vm._v("Modules")])]
                          ),
                        ]),
                        _c("li", { staticClass: "nav-item" }, [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link",
                              class: { active: _vm.tab === 3 },
                              attrs: { href: "#!" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  _vm.tab = 3
                                },
                              },
                            },
                            [_vm._m(3), _c("span", [_vm._v("Usage")])]
                          ),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "tab-content" }, [
                      _c(
                        "div",
                        {
                          staticClass: "tab-pane",
                          class: { "active show": _vm.tab === 1 },
                          staticStyle: { height: "300px" },
                        },
                        [
                          _vm.subscription
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "form-group row ml-1" },
                                  [
                                    _c("div", { staticClass: "col-lg-6 row" }, [
                                      _vm._m(4),
                                      _c("div", { staticClass: "col-3 mt-3" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "kt-switch kt-switch--outline kt-switch--icon kt-switch--success",
                                          },
                                          [
                                            _c("label", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.subscription.disabled,
                                                    expression:
                                                      "subscription.disabled",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  checked: "checked",
                                                  name: "",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.subscription.disabled
                                                  )
                                                    ? _vm._i(
                                                        _vm.subscription
                                                          .disabled,
                                                        null
                                                      ) > -1
                                                    : _vm.subscription.disabled,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.subscription
                                                          .disabled,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "disabled",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "disabled",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.subscription,
                                                        "disabled",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _c("span"),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "col-lg-6 row" }, [
                                      _vm._m(5),
                                      _c("div", { staticClass: "col-3 mt-3" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "kt-switch kt-switch--outline kt-switch--icon kt-switch--success",
                                          },
                                          [
                                            _c("label", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.subscription
                                                        .delinquent,
                                                    expression:
                                                      "subscription.delinquent",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  checked: "checked",
                                                  name: "",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.subscription.delinquent
                                                  )
                                                    ? _vm._i(
                                                        _vm.subscription
                                                          .delinquent,
                                                        null
                                                      ) > -1
                                                    : _vm.subscription
                                                        .delinquent,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.subscription
                                                          .delinquent,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "delinquent",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "delinquent",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.subscription,
                                                        "delinquent",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _c("span"),
                                            ]),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "form-group col-lg-6" },
                                  [
                                    _c("label", [
                                      _vm._v("Subscription Message"),
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.subscription
                                              .schoolSubscriptionMessage,
                                          expression:
                                            "subscription.schoolSubscriptionMessage",
                                        },
                                      ],
                                      staticClass: "form-control",
                                      attrs: { type: "email" },
                                      domProps: {
                                        value:
                                          _vm.subscription
                                            .schoolSubscriptionMessage,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.subscription,
                                            "schoolSubscriptionMessage",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "span",
                                      { staticClass: "form-text text-muted" },
                                      [
                                        _vm._v(
                                          "School Users will see this message if marked delinquent or disabled"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._m(6),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tab-pane fade",
                          class: { "active show": _vm.tab === 2 },
                        },
                        [
                          _vm.subscription
                            ? _c("div", { staticClass: "row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row col-xl-4 col-lg-6 px-4 py-2",
                                  },
                                  [
                                    _vm._m(7),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-3 mt-3" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "kt-switch kt-switch--outline kt-switch--icon kt-switch--danger",
                                          },
                                          [
                                            _c("label", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.subscription
                                                        .adminLocked,
                                                    expression:
                                                      "subscription.adminLocked",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  checked: "checked",
                                                  name: "",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.subscription.adminLocked
                                                  )
                                                    ? _vm._i(
                                                        _vm.subscription
                                                          .adminLocked,
                                                        null
                                                      ) > -1
                                                    : _vm.subscription
                                                        .adminLocked,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.subscription
                                                          .adminLocked,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "adminLocked",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "adminLocked",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.subscription,
                                                        "adminLocked",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _c("span"),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row col-xl-4 col-lg-6 px-4 py-2",
                                  },
                                  [
                                    _vm._m(8),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-3 mt-3" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "kt-switch kt-switch--outline kt-switch--icon kt-switch--success",
                                          },
                                          [
                                            _c("label", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.subscription
                                                        .gradingEnabled,
                                                    expression:
                                                      "subscription.gradingEnabled",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  checked: "checked",
                                                  name: "",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.subscription
                                                      .gradingEnabled
                                                  )
                                                    ? _vm._i(
                                                        _vm.subscription
                                                          .gradingEnabled,
                                                        null
                                                      ) > -1
                                                    : _vm.subscription
                                                        .gradingEnabled,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.subscription
                                                          .gradingEnabled,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "gradingEnabled",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "gradingEnabled",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.subscription,
                                                        "gradingEnabled",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _c("span"),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row col-xl-4 col-lg-6 px-4 py-2",
                                  },
                                  [
                                    _vm._m(9),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-3 mt-3" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "kt-switch kt-switch--outline kt-switch--icon kt-switch--success",
                                          },
                                          [
                                            _c("label", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.subscription
                                                        .anecdotalsEnabled,
                                                    expression:
                                                      "subscription.anecdotalsEnabled",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  checked: "checked",
                                                  name: "",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.subscription
                                                      .anecdotalsEnabled
                                                  )
                                                    ? _vm._i(
                                                        _vm.subscription
                                                          .anecdotalsEnabled,
                                                        null
                                                      ) > -1
                                                    : _vm.subscription
                                                        .anecdotalsEnabled,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.subscription
                                                          .anecdotalsEnabled,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "anecdotalsEnabled",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "anecdotalsEnabled",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.subscription,
                                                        "anecdotalsEnabled",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _c("span"),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row col-xl-4 col-lg-6 px-4 py-2",
                                  },
                                  [
                                    _vm._m(10),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-3 mt-3" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "kt-switch kt-switch--outline kt-switch--icon kt-switch--success",
                                          },
                                          [
                                            _c("label", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.subscription
                                                        .reportCardsEnabled,
                                                    expression:
                                                      "subscription.reportCardsEnabled",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  checked: "checked",
                                                  name: "",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.subscription
                                                      .reportCardsEnabled
                                                  )
                                                    ? _vm._i(
                                                        _vm.subscription
                                                          .reportCardsEnabled,
                                                        null
                                                      ) > -1
                                                    : _vm.subscription
                                                        .reportCardsEnabled,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.subscription
                                                          .reportCardsEnabled,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "reportCardsEnabled",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "reportCardsEnabled",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.subscription,
                                                        "reportCardsEnabled",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _c("span"),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row col-xl-4 col-lg-6 px-4 py-2",
                                  },
                                  [
                                    _vm._m(11),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-3 mt-3" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "kt-switch kt-switch--outline kt-switch--icon kt-switch--success",
                                          },
                                          [
                                            _c("label", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.subscription
                                                        .reportingEnabled,
                                                    expression:
                                                      "subscription.reportingEnabled",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  checked: "checked",
                                                  name: "",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.subscription
                                                      .reportingEnabled
                                                  )
                                                    ? _vm._i(
                                                        _vm.subscription
                                                          .reportingEnabled,
                                                        null
                                                      ) > -1
                                                    : _vm.subscription
                                                        .reportingEnabled,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.subscription
                                                          .reportingEnabled,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "reportingEnabled",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "reportingEnabled",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.subscription,
                                                        "reportingEnabled",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _c("span"),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row col-xl-4 col-lg-6 px-4 py-2",
                                  },
                                  [
                                    _vm._m(12),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-3 mt-3" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "kt-switch kt-switch--outline kt-switch--icon kt-switch--success",
                                          },
                                          [
                                            _c("label", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.subscription
                                                        .encryptionEnabled,
                                                    expression:
                                                      "subscription.encryptionEnabled",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  checked: "checked",
                                                  name: "",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.subscription
                                                      .encryptionEnabled
                                                  )
                                                    ? _vm._i(
                                                        _vm.subscription
                                                          .encryptionEnabled,
                                                        null
                                                      ) > -1
                                                    : _vm.subscription
                                                        .encryptionEnabled,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.subscription
                                                          .encryptionEnabled,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "encryptionEnabled",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "encryptionEnabled",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.subscription,
                                                        "encryptionEnabled",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _c("span"),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row col-xl-4 col-lg-6 px-4 py-2",
                                  },
                                  [
                                    _vm._m(13),
                                    _c(
                                      "div",
                                      { staticClass: "col-md-3 mt-3" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "kt-switch kt-switch--outline kt-switch--icon kt-switch--success",
                                          },
                                          [
                                            _c("label", [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.subscription
                                                        .communicationEnabled,
                                                    expression:
                                                      "subscription.communicationEnabled",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "checkbox",
                                                  checked: "checked",
                                                  name: "",
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.subscription
                                                      .communicationEnabled
                                                  )
                                                    ? _vm._i(
                                                        _vm.subscription
                                                          .communicationEnabled,
                                                        null
                                                      ) > -1
                                                    : _vm.subscription
                                                        .communicationEnabled,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a =
                                                        _vm.subscription
                                                          .communicationEnabled,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "communicationEnabled",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.subscription,
                                                            "communicationEnabled",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.subscription,
                                                        "communicationEnabled",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _c("span"),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.subscription
                            ? _c("div", { staticClass: "row pt-4 px-4" }, [
                                _c(
                                  "div",
                                  { staticClass: "col-xl-6 col-lg-4" },
                                  [
                                    _c("div", { staticClass: "form-group" }, [
                                      _c("label", [
                                        _vm._v("Communication Plan"),
                                      ]),
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.subscription
                                                  .communicationPlan,
                                              expression:
                                                "subscription.communicationPlan",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          staticStyle: { "max-width": "200px" },
                                          attrs: {
                                            disabled:
                                              !_vm.subscription
                                                .communicationEnabled,
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  _vm.subscription,
                                                  "communicationPlan",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                              _vm.setCommunicationPlan,
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            { domProps: { value: "trial" } },
                                            [_vm._v(" Trial ")]
                                          ),
                                          _c(
                                            "option",
                                            {
                                              domProps: { value: "essential" },
                                            },
                                            [_vm._v(" Essential ")]
                                          ),
                                          _c(
                                            "option",
                                            { domProps: { value: "team" } },
                                            [_vm._v(" Team ")]
                                          ),
                                          _c(
                                            "option",
                                            {
                                              domProps: { value: "corporate" },
                                            },
                                            [_vm._v(" Corporate ")]
                                          ),
                                          _c(
                                            "option",
                                            {
                                              domProps: { value: "enterprise" },
                                            },
                                            [_vm._v(" Enterprise ")]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                !_vm.subscription.deleted
                                  ? _c(
                                      "div",
                                      { staticClass: "col-xl-3 col-lg-4" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c("label", [_vm._v("SMS Quota")]),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.subscription.smsQuota,
                                                  expression:
                                                    "subscription.smsQuota",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                disabled:
                                                  !_vm.subscription
                                                    .communicationEnabled,
                                                type: "number",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.subscription.smsQuota,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.subscription,
                                                    "smsQuota",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "form-text text-muted",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      isNaN(
                                                        _vm.subscription
                                                          .smsQuota
                                                      )
                                                        ? 0
                                                        : `~\$${(
                                                            _vm.subscription
                                                              .smsQuota *
                                                            _vm.inboundSmsCost
                                                          ).toFixed(2)}`
                                                    ) +
                                                    " per month "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                !_vm.subscription.deleted
                                  ? _c(
                                      "div",
                                      { staticClass: "col-xl-3 col-lg-4" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "form-group" },
                                          [
                                            _c("label", [
                                              _vm._v("Email Quota"),
                                            ]),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.subscription.emailQuota,
                                                  expression:
                                                    "subscription.emailQuota",
                                                },
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                disabled:
                                                  !_vm.subscription
                                                    .communicationEnabled,
                                                type: "number",
                                              },
                                              domProps: {
                                                value:
                                                  _vm.subscription.emailQuota,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.subscription,
                                                    "emailQuota",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "form-text text-muted",
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      isNaN(
                                                        _vm.subscription
                                                          .emailQuota
                                                      )
                                                        ? 0
                                                        : `~\$${(
                                                            (_vm.subscription
                                                              .emailQuota /
                                                              1000) *
                                                            _vm.emailCost
                                                          ).toFixed(2)}`
                                                    ) +
                                                    " per month "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tab-pane fade",
                          class: { "active show": _vm.tab === 3 },
                        },
                        [
                          _vm.subscription.schoolMonthlyUsages
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "row" },
                                  _vm._l(
                                    ["SMS", "Email"],
                                    function (type, idx) {
                                      return _c(
                                        "div",
                                        {
                                          key: `usage_data_${type}, ${idx}`,
                                          staticClass: "kt-widget4 col-lg-6",
                                        },
                                        [
                                          _vm.subscription.schoolMonthlyUsages
                                            .length
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kt-widget4__chart",
                                                },
                                                [
                                                  _c(
                                                    "SchoolSubscriptionsUsageLineChart",
                                                    {
                                                      attrs: {
                                                        "school-monthly-usages":
                                                          _vm.subscription
                                                            .schoolMonthlyUsages,
                                                        type: type,
                                                        height: 130,
                                                      },
                                                    }
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "kt-widget4__items kt-widget4__items--bottom kt-portlet__space-x kt-margin-b-20 mt-3",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kt-widget4__item",
                                                },
                                                [
                                                  _vm._m(14, true),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kt-widget4__info",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "kt-widget4__title",
                                                          attrs: { href: "#" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(type) +
                                                              " Usage Data "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "kt-widget4__sub",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Running Total for school year "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "kt-widget4__ext",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "kt-widget4__number float-right",
                                                          class: [
                                                            _vm.getTotalUsageClass(
                                                              type
                                                            ),
                                                          ],
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-font-lg",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getTotalUsage(
                                                                    type
                                                                  )
                                                                ) + " Sent"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-font-bolder",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "$" +
                                                                  _vm._s(
                                                                    _vm.getTotalCost(
                                                                      type
                                                                    )
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kt-widget4__item",
                                                },
                                                [
                                                  _vm._m(15, true),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kt-widget4__info",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "kt-widget4__title",
                                                          attrs: { href: "#" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.currentMonth
                                                              ) +
                                                              " Usage "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "kt-widget4__sub",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Running total for current month "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "kt-widget4__ext",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "kt-widget4__number float-right",
                                                          class: [
                                                            _vm.getMonthUsageClass(
                                                              type
                                                            ),
                                                          ],
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-font-lg",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.getMonthUsage(
                                                                    type
                                                                  )
                                                                ) + " Sent"
                                                              ),
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-font-bolder",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "$" +
                                                                  _vm._s(
                                                                    _vm.getMonthCost(
                                                                      type
                                                                    )
                                                                  )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kt-widget4__item",
                                                },
                                                [
                                                  _vm._m(16, true),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "kt-widget4__info",
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "kt-widget4__title",
                                                          attrs: { href: "#" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(type) +
                                                              " Quota "
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "kt-widget4__sub",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                type === "SMS"
                                                                  ? `$${_vm.outboundSmsCost} per Outbound SMS`
                                                                  : `$${_vm.emailCost}0 per 1000 emails sent`
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "kt-widget4__ext",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "kt-widget4__number float-right",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-font-lg",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    type ===
                                                                      "SMS"
                                                                      ? _vm
                                                                          .subscription
                                                                          .smsQuota ||
                                                                          "Unlimited"
                                                                      : _vm
                                                                          .subscription
                                                                          .emailQuota ||
                                                                          "Unlimited"
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c("br"),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "kt-font-xs",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " per month "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-portlet__head-label" }, [
      _c(
        "h3",
        { staticClass: "kt-portlet__head-title kt-font-light kt-font-boldest" },
        [_vm._v(" Subscription ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "fa flaticon-piggy-bank" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "fa flaticon-interface-6" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "fa flaticon-statistics" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-lg-9 col-form-label" }, [
      _c("span", { staticClass: "kt-font-lg" }, [_vm._v("Disabled")]),
      _c("br"),
      _c("span", [
        _vm._v("Disables access to all SyncGrades features for school year"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-lg-9 col-form-label" }, [
      _c("span", { staticClass: "kt-font-lg" }, [_vm._v("Delinquent")]),
      _c("br"),
      _c("span", [
        _vm._v("Warns school users of delinquent status for school year"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-widget28__tab-items" }, [
      _c("div", { staticClass: "kt-widget28__tab-item" }, [
        _c("span", { staticClass: "font-italic" }, [
          _vm._v("Invoicing Coming soon"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-md-9 col-form-label" }, [
      _c("span", { staticClass: "kt-font-lg kt-font-danger" }, [
        _vm._v("Super Admin Locked"),
      ]),
      _c("br"),
      _c("span", { staticClass: "kt-font-danger" }, [
        _vm._v("Locks modules changes from school admins"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-md-9 col-form-label" }, [
      _c("span", { staticClass: "kt-font-lg" }, [_vm._v("Grading")]),
      _c("br"),
      _c("span", [_vm._v("Enables GradeBook capabilities for school year")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-md-9 col-form-label" }, [
      _c("span", { staticClass: "kt-font-lg" }, [_vm._v("Anecdotals")]),
      _c("br"),
      _c("span", [_vm._v("Enables Anecdotal Logs and Badges for school year")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-md-9 col-form-label" }, [
      _c("span", { staticClass: "kt-font-lg" }, [_vm._v("Report Cards")]),
      _c("br"),
      _c("span", [
        _vm._v("Enables Report Card Grade submission for school year"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-md-9 col-form-label" }, [
      _c("span", { staticClass: "kt-font-lg" }, [_vm._v("Report Generation")]),
      _c("br"),
      _c("span", [
        _vm._v("Enables Progress Report/Report Card pdf generation"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-md-9 col-form-label" }, [
      _c("span", { staticClass: "kt-font-lg" }, [_vm._v("Encryption")]),
      _c("br"),
      _c("span", [_vm._v("Enables Data Encryption for school year")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { staticClass: "col-md-9 col-form-label" }, [
      _c("span", { staticClass: "kt-font-lg" }, [_vm._v("Communication")]),
      _c("br"),
      _c("span", [_vm._v("Enables Communication features for school year")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-widget4__img kt-widget4__img--logo" }, [
      _c("img", {
        attrs: { src: "assets/media/client-logos/logo3.png", alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-widget4__img kt-widget4__img--logo" }, [
      _c("img", {
        attrs: { src: "assets/media/client-logos/logo1.png", alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "kt-widget4__img kt-widget4__img--logo" }, [
      _c("img", {
        attrs: { src: "assets/media/client-logos/logo1.png", alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }