function SchoolSubscription(props) {
    this.schoolSubscriptionId = props.schoolSubscriptionId;
    this.externalSchoolId = props.externalSchoolId;
    this.deleted = 'deleted' in props ? props.deleted : false;
    this.schoolYear = props.schoolYear;
    this.purchaseOrderNumber = props.purchaseOrderNumber || null;
    this.delinquent = props.delinquent;
    this.disabled = props.disabled;
    this.schoolSubscriptionMessage = props.schoolSubscriptionMessage;
    this.certifiedPurchaseOrder = props.certifiedPurchaseOrder || null;
    this.invoice = props.invoice || null;
    this.adminLocked = props.adminLocked;
    this.anecdotalsEnabled = props.anecdotalsEnabled;
    this.gradingEnabled = props.gradingEnabled;
    this.encryptionEnabled = props.encryptionEnabled;
    this.reportCardsEnabled = props.reportCardsEnabled;
    this.reportingEnabled = props.reportingEnabled;
    this.communicationEnabled = props.communicationEnabled;
    this.communicationPlan = props.communicationPlan;
    this.communicationAdminSet = props.communicationAdminSet;

//use pauls terminology for plans
    this.adminLocked = props.adminLocked;
    this.communicationEnabled = props.communicationEnabled;
    this.anecdotalsEnabled = props.anecdotalsEnabled;
    this.gradingEnabled = props.gradingEnabled;
    this.encryptionEnabled = props.encryptionEnabled;
    this.reportCardsEnabled = props.reportCardsEnabled;
    this.reportingEnabled = props.reportingEnabled;
    this.communicationPlan = props.communicationPlan;

    this.emailQuota = props.emailQuota || null;
    this.emailUsage = props.emailUsage || 0;
    this.smsQuota = props.smsQuota || null;
    this.smsUsage = props.smsUsage || 0;

    this.schoolMonthlyUsages = props.schoolMonthlyUsages || [];
}

module.exports = SchoolSubscription;
